import {Component, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent {
    @Output() closed = new EventEmitter<void>();

    startingDate: Date = new Date('2024-10-07T23:59:59'); // Data inizio periodo di ferie
    targetDate: Date = new Date('2024-10-20T23:59:59'); // Data fine periodo di ferie

    closePopup() {
        this.closed.emit();
    }
}
